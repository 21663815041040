import React from 'react'

export default function HomeContent() {
  return (
    <section className="container">
        <div className="columns features">
            <div className="column is-4">
                <div className="card is-shady">
                    <div className="card-image has-text-centered">
                        <i className="fa fa-paw"></i>
                    </div>
                    <div className="card-content">
                        <div className="content">
                            <h4>Simplify Data Integration, Amplify Impact</h4>
                            <p>
The Target Population Analytics toolset is your gateway to less time on data integration and more time on developing impactful policies. Effortlessly import and link data from various programmatic systems. Visualize matched and linked client populations with ease, based on diverse criteria like service history, needs, risks, and protective factors. Transform the way you analyze, recommend policies, and guide practices with casegraph.</p>
                            <p><a href="/">Learn more</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column is-4">
                <div className="card is-shady">
                     <div className="card-image has-text-centered">
                        <i className="fa fa-empire"></i>
                    </div>
                    <div className="card-content">
                        <div className="content">
                            <h4>Redefining Client Data Analysis</h4>
                            <p>
With our Cohort Matching tool, bridge the gaps in care delivery by matching and linking client data across agencies and programs. Identify care gaps and duplications, and delve deep into cross-sectional and longitudinal analyses. Discover patterns of fragmentation and disconnects in care delivery. Employ advanced analytics and statistical learning methods to iteratively test and refine approaches for better outcomes.</p>
                            <p><a href="/">Learn more</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column is-4">
                <div className="card is-shady">
                    <div className="card-image has-text-centered">
                        <i className="fa fa-apple"></i>
                    </div>
                     <div className="card-content">
                        <div className="content">
                            <h4>Harmonizing Client Care Across Systems</h4>
                            <p>
Navigating multiple Systems of Care often leads to fragmented client data and service duplication. The Care Coordination tool by casegraph elegantly solves this by integrating data across diverse care programs. Gain a unified view of clients' service histories, needs, and challenges, and make informed decisions without the usual complexities of data silos.

This tool not only streamlines service planning but also ensures comprehensive care management. By connecting disparate data systems, it paves the way for effective, coordinated care delivery. Embrace the power of unified data with casegraph, and transform the way you approach client care coordination.</p>
                            <p><a href="/">Learn more</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
